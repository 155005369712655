import React from "react";

require("../../styles/site.css");

const Experience = () => {
  return (
    <div class="resume-flex-exp">
      <span>Recent Professional Experience:</span>
      <div>
        <span>
          Principal Consultant - Rural Sourcing, Inc. | Mobile, AL | May 2018 to
          Present
        </span>
        <ul>
          <li>
            Team Lead for multiple client engagements. Developing solutions and
            modernizing legacy applications using technologies including C#,
            ASP.Net MVC, JavaScript, React and SQL Server. RDBMS
          </li>
          <ul>
            <li>
              Implemented ASP.NET MVC website used to manage Self-Directed Home
              Care.
            </li>
            <li>
              Integrated client’s flagship ASP.NET MVC product within a React
              wrapper.
            </li>
            <li>Converted ASP.NET Web Forms product to ASP.NET MVC.</li>
            <li>
              Integrated system with NCR Collector Solutions for credit card
              payments.
            </li>
            <li>
              Implemented enhancements and bug fixes for clients .Net RESTful
              API.
            </li>
          </ul>
          <li>
            Chair of the Rural Sourcing Professional Services Orientation
            Services (RPSOS) Program, a training series of eight presentations
            completed by all new hires. I have hosted over fifty RPSOS
            company-wide sessions with an average attendance of 125 to 175
            colleagues.
          </li>
          <li>
            Manage 3 to 5 colleagues, providing mentoring and career advancement
            guidance.
          </li>
        </ul>
      </div>
      <div>
        <span>
          Senior Software Developer - Austal USA | Mobile, AL | May 2015 to May
          2018
        </span>
        <br></br>
        <span>
          Contract Developer - TEKSystems at Austal USA | Mobile, AL | October
          2014 to May 2015
        </span>
        <ul>
          <li>
            Led effort to standardize development life cycle for intranet
            websites. C reated procedures for IIS configuration, product
            release, user testing and user feedback.
          </li>
          <li>
            Developed ASP.NET MVC solutions for Injury Reporting, Post Delivery
            Work and Master Equipment Management.
          </li>
          <li>
            Upgraded the Bill of Materials application from using VB and
            MS-Access DB to a C# solution using SQL Server.
          </li>
          <li>
            Authored coding standards, tech stack guidelines and department SOP.
          </li>
          <li>
            This was a Contract-to-Hire position through TekSystems from October
            2014 to May 2015 at which time I was hired on as a full-time
            employee.
          </li>
        </ul>
      </div>
      <div>
        <span>
          Team Lead - Bowhead Science & Technology | Mobile, AL | May 2011 to
          October 2014
        </span>
        <ul>
          <li>
            Led team of 15 to 20 Software Developers, System Analysts and GIS Specialists on contract with US Army Corps of Engineers, Mobile District.
          </li>
          <li>
            Converted Sediment Budget Analysis Tool (SBAS) to C# and ArcObjects 2.0. Designed new relational data model for next version of this ArcMap tool
          </li>
          <li>
            Led 3+ year effort to implement new Hydro Survey tool using Python and C# with ArcGIS extensionsfor all 21 coastal districts of the US Army Corps of Engineers.
           
          </li>
          <li>
            Implemented survey tool used to predict future shoaling at US Navy
            Fleet Concentration Areas.
          </li>
          <li>
            Implemented a water level monitoring system which alerts the Mobile
            District Archaeologist to potential exposure of sensitive sites due
            to low water conditions. Apps and Tools written in Python with
            ArcGIS extensions
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Experience;
