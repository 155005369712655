import React from "react";
import Layout from "../components/layout";
import Experience from "../components/resume/experience";
import ExperienceOld from "../components/resume/experienceold";
import Summary from "../components/resume/summary";
import TechStack from "../components/resume/techstack";
import Education from "../components/resume/education";

const ResumePage = () => {
  return (
    <Layout pageTitle="">
      <div class="resume-flex-container">
        <Summary />
        <TechStack />
        <Experience />
        <ExperienceOld />
        <Education />
      </div>
    </Layout>
  );
};

export default ResumePage;
