import React from "react";

require("../../styles/site.css");

const ExperienceOld = () => {
  return (
    <div class="resume-flex-exp-old">
      <span>Other Professional Experience:</span>
      <div>
        Software Developer - ACO Information Services | Mobile, AL | August 2007
        - February 2011
      </div>
      <div>
        IT Developer - First Watch Restaurants | Sarasota, FL | April 2005 -
        August 2007
      </div>
      <div>
        Senior Developer - Data Masons Software | Sarasota, FL | October 2004 -
        April 2005
      </div>
      <div>
        Senior Programmer - Morgan Beaumont | Sarasota, FL | April 2004 -
        October 2004
      </div>
      <div>
        Programmer Analyst - Comdial Corporation | Sarasota, FL | March 2001 -
        March 2004
      </div>
      <div>
        Senior Software Engineer - Harris Corporation | Melbourne, FL August |
        2000 - January 2001
      </div>
      <div>
        Manager of Information Services - Johns Eastern Company | Sarasota, FL |
        July 1994 - August 2000
      </div>
      <div>
        Senior Support Engineer - Unify Corporation | Sacramento, CA April |
        1992 - July 1994
      </div>
      <div>
        Systems Programmer - Insight Distribution Systems | Baltimore, MD | July
        1990 - April 1992
      </div>
      <div>
        Programmer - Convergent Dealership Systems | Baltimore, MD | August 1987
        - July 1990
      </div>
    </div>
  );
};

export default ExperienceOld;
