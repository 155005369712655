import React from "react";

require("../../styles/site.css");

const TechStack = () => {
  return (
    <div className="resume-flex-techstack">
      <div className="resume-flex-techstack-header">Skilled:</div>
      <div className="resume-flex-techstack-section">
        <div>C#</div>
        <div>.Net Framework</div>
        <div>ASP.Net MVC</div>
        <div>SQL Server</div>
      </div>

      <div className="resume-flex-techstack-header">Knowledgeable:</div>
      <div className="resume-flex-techstack-section">
        <div>JavaScript</div>
        <div>React</div>
        <div>GraphQL</div>
        <div>Unity</div>
      </div>
    </div>
  );
};

export default TechStack;
