import React from 'react'


require('../../styles/site.css')

const Education = () => {
  return (
    <div className='resume-flex-edu'>    
      <span>
          Education:
      </span>
      <div> 
        <br></br>
        Associate in Computer Technology- 1987, Essex Community College, Baltimore, MD
      </div>
    </div>
  )

}

export default Education