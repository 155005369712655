import React from 'react'

require('../../styles/site.css')

const Summary = () => {
  return (
    <div className='resume-flex-sum'>
      <div>    
        Extroverted Full Stack Developer with 35+ years of experience in programming, supervision and mentoring.
      </div>
    </div>
  )

}

export default Summary